.input {
  border: 1px solid #4F4F4F;
  background: transparent;
  border-radius: 100px;
  height: 37px;
  font-weight: 400;
  padding: 0 21px;
  font-size: 13px;
  color: #9A9AB0;
  outline: none;
  width: 120px;
}

.removePrice {
  position: relative;
  top: 0px;
  right: 25px;
}

.settingBtn {
  background: #03FE9D;
  border: 0;
  border-radius: 100px;
  padding: 9px 28px;
  font-weight: 600;
  margin-left: auto;
}

