.tableData {
    margin-top: 35px;
}

.tableData h3 {
    font-size: 20px;
    color: #fff;
    font-weight: 400;
    margin-bottom: 15px;
}

.tableData table tr th {
    background: #1A1D1E;
    padding: 11px 20px;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
}

.tableData table {
    width: 100%;
}

.tableData table tr td {
    padding: 11px 20px;
    color: #fff;
    font-size: 13px;
    font-weight: 400;
}

.tableData table tr td img {
    margin-right: 5px;
}

.tableData table tr td label {
    color: #A1A1A1;
    font-size: 12px;
}

.tableData table tr td.green {
    color: #03FE9D;
}

.tableData table tr td.red {
    color: #F92C2C;
}

.tableData table span {
    border: 1px solid #212325;
    width: 33px;
    height: 33px;
    display: flex;
    border-radius: 30px;
    align-items: center;
    justify-content: center;
}

.tableData table tr td span img {
    margin: 0;
}

.topFilter ul {
    display: flex;
    justify-content: flex-end;
    padding: 0;
    margin: 0;
}

.topFilter ul li {
    list-style: none;
    margin-left: 40px;
}

.topFilter ul li span {
    font-size: 16px;
    color: #96A5B8;
    padding-right: 10px;
}

.topFilter ul li select {
    background: transparent;
    border: 0;
    font-size: 16px;
    color: #fff;
    padding-right: 4px;
}

.topFilter ul li select {
    background: transparent;
    border: 0;
    font-size: 16px;
    color: #fff;
    padding-right: 4px;
    outline: none;
}

.topFilter {
    padding-bottom: 17px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 40px;
}

.profileData {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #D9D9D94D;
    padding-bottom: 40px;
    margin-bottom: 40px;
    margin-top: 60px;
}

.rightUser button {
    background: #1A1D1E;
    border: 0;
    color: #fff;
    font-size: 16px;
    border-radius: 100px;
    padding: 10px 30px;
    margin-left: 12px;
}

.rightUser button img {
    margin-right: 5px;
}

.rightUser button.detet {
    color: #FF0000;
}

.filterHead h4 {
    font-size: 28px;
    color: #fff;
    margin-bottom: 10px;
}

td.green {
    color: #03FE9D;
}

.rightUser span {
    font-size: 16px;
    background: #77ED91;
    padding: 10px 24px;
    border-radius: 60px;
    font-weight: 600;
    margin-left: 12px;
}

.usrData h6 {
    color: #0ECA38;
    font-size: 13px;
    font-style: italic;
    margin: 0;
}

.usrData h5 {
    font-size: 28px;
    color: #fff;
    margin: 6px 0;
}

.leftUser p {
    font-size: 15px;
    color: #fff;
    margin: 0;
}

.leftUser {
    display: flex;
    align-items: center;
}

.leftUser img {
    margin-right: 10px;
}

.filterLeft {
    padding: 10px 0;
}

.filterLeft button {
    background: transparent;
    border: 0;
    color: #fff;
    font-size: 14px;
    border: 1px solid #fff;
    border-radius: 100px;
    padding: 8px 20px;
    margin-right: 10px;
}

.filterLeft button.active {
    background: #fff;
    color: #000;
    font-weight: 600;
}

button.inactivebt {
    border: 1px solid #EF3D16;
    font-size: 14px;
    background: transparent;
    color: #EF3D16;
    border-radius: 100px;
    padding: 4px 18px;
    min-width: 83px;
    text-align: center;
}
.design_heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 0.25rem; /* Adjust margin as needed */
    margin-bottom: 0.25rem; /* Adjust margin as needed */
    padding: 7px 12px; /* Standard Bootstrap padding */
    border-radius: 5px; 
    color: #ffffff;
    background-color: #174433;
    margin-top: 20px;
}

  .design_demo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 0.25rem; /* Adjust margin as needed */
    margin-bottom: 0.25rem; /* Adjust margin as needed */
    padding: 7px 12px; /* Standard Bootstrap padding */
    border-radius: 5px; 
    color: #ffffff;
    background-color: #111414;
}

.design_demo:hover {
    background-color: #152424; /* Light hover effect */
}
button.activebt {
    border: 1px solid #2BC155;
    font-size: 14px;
    background: transparent;
    color: #fff;
    border-radius: 100px;
    padding: 4px 18px;
    min-width: 83px;
    text-align: center;
    background: #2BC155;
}

.planslIstinner {
    background: #111414;
    padding: 22px;
    border-radius: 10px;
    position: relative;
    margin-bottom: 20px;
}

.planslIstinner h5 {
    color: #fff;
    margin: 70px 0 7px;
    position: relative;
    z-index: 1;
    font-size: 18px;
    min-height: 43px;
}

img.flg {
    position: absolute !important;
    bottom: 0;
    left: 0;
    right: 0;
    width: 85% !important;
    height: auto !important;
    margin: 0 auto;
}

.PlansList {
    padding: 20px 140px;
    border-top: 1px solid #dddddd40;
}

.flgcnt {
    max-width: 40px;
    max-height: 40px;
    object-fit: cover;
    border-radius: 100px;
    background: #fff;
}

@media (max-width:1700px) {
    .PlansList {
        padding: 20px 0px;
    }
}

@media (max-width:1400px) {
    .planslIstinner h5 {
        margin: 42px 0 7px;
        min-height: 25px;
    }
}

.zIndex {
    z-index: 1000;
}

.loading {
    color: #fff;
    text-align: center;
    margin-top: 100px;
    font-size: 22px;
}